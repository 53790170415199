import numeral from 'numeral'
import {
  DynamoFile,
  FileCommentTopic,
  FileStatus,
  FolderItem,
  TextcutRectangle,
} from '../../types'
import { Dispatch } from 'react'
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import { fileContainsComment, fileSizeLessThan5MB } from '../../utils/common'
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import { ClipLoader } from 'react-spinners'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { PopupTextType } from '../Popup'

const DELETE_FILE = {
  header: 'Delete File',
  content: 'Are you sure to delete the file?',
}

type Props = {
  files: DynamoFile[]
  onFileClick: (file: DynamoFile) => () => Promise<void>
  showPopup: (popup: PopupTextType) => void
  deletePDF: (fileId: string) => Promise<void>
  isTrimmable: (fileId: string) => any
  trimFile: (fileId: string) => void
  isExportable: (fileId: string) => boolean
  exportPDF: (file: DynamoFile) => () => Promise<void>
  downloadFile: (file: DynamoFile) => () => Promise<void>
  folders?: FolderItem[]
  folderStack?: FolderItem[]
  setFolderStack?: Dispatch<React.SetStateAction<FolderItem[]>>
  references: TextcutRectangle[]
  comments: FileCommentTopic[]
  deleteFolder?: (folderItem: FolderItem) => void
  movable?: boolean
  move?: ({
    id,
    name,
    type,
  }: {
    id: string
    name: string
    type: 'folder' | 'file'
  }) => void
  savePdfsToWorkbook: (file: DynamoFile) => Promise<void>
}

type FilePathBreadcrumProps = {
  setFolderStack: Dispatch<React.SetStateAction<FolderItem[]>>
  folderStack: FolderItem[]
}

const renderIndicator = (fileStatus: string) => {
  if (fileStatus === FileStatus.SUCCEEDED)
    return (
      <div className="w-4 h-4 bg-green-200 rounded-full border-2 border-green-600 mr-2"></div>
    )
  else if (isUnhealthyFile(fileStatus))
    return (
      <div className="w-4 h-4  border-red-600 border-2 rounded-full mr-2"></div>
    )
  else
    return (
      <div className="mr-2">
        <ClipLoader color="#FBBF24" size={14} />
      </div>
    )
}

const renderFilePathBreadcrum = ({
  folderStack,
  setFolderStack,
}: FilePathBreadcrumProps) => {
  const stack = [
    { id: null as any, name: 'Files' } as FolderItem,
    ...folderStack,
  ]
  const onPathClick = (idx: number) => () => {
    const tmp = stack.slice(0, idx + 1)
    tmp.shift()
    setFolderStack(tmp)
  }

  return (
    <ol className="inline-flex gap-2">
      {stack.map((path, i) => (
        <li key={i}>
          <div className="flex items-center gap-2 ">
            <div
              className={`text-xl cursor-pointer font-semibold  ${
                i === stack.length - 1 ? 'text-black' : 'text-gray-400'
              }`}
              onClick={onPathClick(i)}
            >
              {path ? path.name : 'Files'}
            </div>
            {i < stack.length - 1 && (
              <div className="flex items-center text-xl font-semibold text-gray-300">
                {'>'}
              </div>
            )}
          </div>
        </li>
      ))}
    </ol>
  )
}

const isUnhealthyFile = (status: string) =>
  status === FileStatus.FAILED ||
  status === FileStatus.INVALID ||
  status === FileStatus.MALICIOUS

const renderUnhealthyFileBadge = (status: string) => {
  switch (status) {
    case FileStatus.FAILED:
      return 'Failed'
    case FileStatus.INVALID:
      return 'Invalid'
    case FileStatus.MALICIOUS:
      return 'Malicious'
    default:
      return 'Something wrong'
  }
}

const FileUnorderedList = ({
  files,
  onFileClick,
  showPopup,
  deletePDF,
  isTrimmable,
  trimFile,
  isExportable,
  exportPDF,
  downloadFile,
  folders,
  folderStack,
  setFolderStack,
  comments,
  deleteFolder,
  movable = false,
  move = () => {},
  savePdfsToWorkbook,
}: Props) => {
  return (
    <ul>
      {folderStack && folderStack?.length !== 0 && setFolderStack && (
        <li
          // onClick={() =>
          //   setFolderStack && folderStack && folderStack.length > 0
          //     ? setFolderStack(folderStack.slice(0, folderStack.length - 1))
          //     : null
          // }
          className="relative rounded-md p-1 py-3"
        >
          {/* <motion.div whileHover={{ scale: 0.98 }}>
                <div className="flex justify-between items-center">
                  <div className="flex w- full">
                    <h3 className="text-2xl font-semibold leading-5">..</h3>
                  </div>
                </div>
              </motion.div> */}
          {renderFilePathBreadcrum({ folderStack, setFolderStack })}
        </li>
      )}
      {folders &&
        folders.length > 0 &&
        folders.map((folder, i) => (
          <li
            className="relative rounded-md p-1 hover:bg-gray-100"
            key={folder.id}
          >
            <div className="">
              <div className="flex justify-between items-center h-11">
                <div
                  className="flex w-full h-full items-center cursor-pointer"
                  onClick={() =>
                    setFolderStack
                      ? setFolderStack([...(folderStack ?? []), folder])
                      : null
                  }
                >
                  <div className="flex items-center mr-2">
                    <FolderOutlinedIcon sx={{ fontSize: 20 }} />
                  </div>

                  <div className="flex items-center">
                    <h3 className="text-sm font-medium leading-5">
                      {folder.name}
                    </h3>
                  </div>
                </div>
                <div className="flex">
                  {movable && (
                    <div className="relative group/move flex justify-center mr-2 hover:bg-gray-500 rounded">
                      <button
                        className="w-full h-full p-2.5 transition ease-in-out delay-100 hover:scale-110"
                        onClick={() =>
                          move({
                            id: folder.id,
                            name: folder.name,
                            type: 'folder',
                          })
                        }
                      >
                        <DriveFileMoveOutlinedIcon
                          sx={{ fontSize: 20, zIndex: 0 }}
                        />
                      </button>
                      <div
                        className={`absolute ${
                          i === folders.length - 1
                            ? folders.length === 1
                              ? 'bottom-8 before:-bottom-1'
                              : 'bottom-14 before:-bottom-1'
                            : 'top-14 before:-top-1'
                        } scale-0 z-10  rounded bg-neutral-700 p-2 text-xs text-white group-hover/move:scale-100  group-hover/move:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
                      >
                        Move
                      </div>
                    </div>
                  )}
                  <div className="relative flex justify-center group/deletefile  hover:bg-gray-500 rounded">
                    <button
                      className="w-full h-full p-2.5 transition ease-in-out delay-100 hover:scale-110"
                      onClick={() =>
                        deleteFolder ? deleteFolder(folder) : null
                      }
                    >
                      <DeleteOutlineOutlinedIcon
                        sx={{ fontSize: 20, zIndex: 0 }}
                      />
                    </button>
                    <div
                      className={`absolute  $absolute ${
                        i === folders.length - 1
                          ? folders.length === 1
                            ? 'bottom-8 before:-bottom-1'
                            : 'bottom-14 before:-bottom-1'
                          : 'top-14 before:-top-1'
                      } scale-0 z-10 rounded bg-neutral-700 p-2 text-xs text-white group-hover/deletefile:scale-100 group-hover/deletefile:w-fit before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:left-0  before:right-0 before:mx-auto`}
                    >
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      {files.map((file, i) => (
        <li key={file.fileId} className={`rounded-md p-1 hover:bg-gray-100`}>
          <div className="">
            <div className="flex justify-between items-center h-11">
              <div
                className="flex w-full h-full justify-start items-center cursor-pointer"
                onClick={onFileClick(file)}
              >
                {renderIndicator(file.status)}
                <div className="mr-2">
                  <h3 className="text-sm font-medium leading-5">
                    {file.fileName}
                  </h3>
                </div>
                {!isUnhealthyFile(file.status) && (
                  <div className="text-xs font-normal leading-4 text-gray-500 mr-2">
                    {numeral(file.originalFileSize).format('0b').toString()}
                  </div>
                )}
                {!file.isLocal && (
                  <div className="flex mr-2 items-center">
                    <CloudOutlinedIcon sx={{ fontSize: 12 }} />
                  </div>
                )}
                {!isUnhealthyFile(file.status) &&
                  fileContainsComment(file, comments) && (
                    <div className="flex mr-2 items-center">
                      <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 12 }} />
                    </div>
                  )}
                {isUnhealthyFile(file.status) && (
                  <div className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                    {renderUnhealthyFileBadge(file.status)}
                  </div>
                )}
              </div>
              {file.status !== FileStatus.SUCCEEDED &&
                file.status !== FileStatus.UPLOADING &&
                file.status !== FileStatus.PROCESSING && (
                  <div className="group/singledeletefile flex justify-center rounded  hover:bg-gray-500">
                    <button
                      className="w-full h-full p-2.5 rounded transition ease-in-out delay-100 hover:scale-110"
                      onClick={() =>
                        showPopup({
                          ...DELETE_FILE,
                          onClick: () => deletePDF(file.fileId),
                        })
                      }
                    >
                      <DeleteOutlineOutlinedIcon sx={{ fontSize: 20 }} />
                    </button>
                    <div
                      className={`absolute ${
                        i === files.length - 1
                          ? files.length === 1
                            ? 'bottom-8 before:-bottom-1'
                            : 'bottom-14 before:-bottom-1'
                          : 'top-14 before:-top-1'
                      }
                          scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/singledeletefile:scale-100 group-hover/singledeletefile:w-fit before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-1  before:right-0 before:mx-auto`}
                    >
                      Delete
                    </div>
                  </div>
                )}
              {file.status === FileStatus.SUCCEEDED && (
                <div className="flex items-center group/ellipses">
                  {movable && (
                    <div className="relative group/move hidden group-hover/ellipses:flex group-hover/ellipses:justify-center mr-2 hover:bg-gray-500 rounded">
                      <button
                        className="w-full h-full p-2.5 transition ease-in-out delay-100 hover:scale-110"
                        onClick={() =>
                          move({
                            id: file.fileId,
                            name: file.fileName,
                            type: 'file',
                          })
                        }
                      >
                        <DriveFileMoveOutlinedIcon sx={{ fontSize: 20 }} />
                      </button>
                      <div
                        className={`absolute ${
                          i === files.length - 1
                            ? files.length === 1
                              ? 'bottom-8 before:-bottom-1'
                              : 'bottom-14 before:-bottom-1'
                            : 'top-14 before:-top-1'
                        } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/move:scale-100 group-hover/move:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
                      >
                        Move
                      </div>
                    </div>
                  )}
                  {!file.isLocal &&
                    fileSizeLessThan5MB(file.originalFileSize) && (
                      <div className="relative group/local hidden group-hover/ellipses:flex group-hover/ellipses:justify-center mr-2 hover:bg-gray-500 rounded">
                        <button
                          className="w-full h-full p-2.5 transition ease-in-out delay-100 hover:scale-110"
                          onClick={() => savePdfsToWorkbook(file)}
                        >
                          <CloudDownloadOutlinedIcon sx={{ fontSize: 20 }} />
                        </button>
                        <div
                          className={`absolute ${
                            i === files.length - 1
                              ? files.length === 1
                                ? 'bottom-8 before:-bottom-1'
                                : 'bottom-14 before:-bottom-1'
                              : 'top-14 before:-top-1'
                          } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/local:scale-100 group-hover/local:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
                        >
                          Save file to local
                        </div>
                      </div>
                    )}
                  {isTrimmable(file.fileId) && (
                    <div className="relative group/trim hidden group-hover/ellipses:flex group-hover/ellipses:justify-center mr-2 hover:bg-gray-500 rounded">
                      <button
                        className="p-2.5 w-full h-full transition ease-in-out delay-100 hover:scale-110"
                        onClick={() => trimFile(file.fileId)}
                      >
                        <ContentCutOutlinedIcon sx={{ fontSize: 20 }} />
                      </button>
                      <div
                        className={`absolute ${
                          i === files.length - 1
                            ? files.length === 1
                              ? 'bottom-8 before:-bottom-1'
                              : 'bottom-14 before:-bottom-1'
                            : 'top-14 before:-top-1'
                        } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/trim:scale-100 group-hover/trim:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
                      >
                        Delete pages without links
                      </div>
                    </div>
                  )}
                  {isExportable(file.fileId) && (
                    <div className="relative hidden group/export group-hover/ellipses:flex group-hover/ellipses:justify-center mr-2 hover:bg-gray-500 rounded">
                      <button
                        className="p-2.5 w-full h-full transition ease-in-out delay-100 hover:scale-110"
                        onClick={exportPDF(file)}
                      >
                        <SimCardDownloadOutlinedIcon sx={{ fontSize: 20 }} />
                      </button>
                      <div
                        className={`absolute ${
                          i === files.length - 1
                            ? files.length === 1
                              ? 'bottom-8 before:-bottom-1'
                              : 'bottom-14 before:-bottom-1'
                            : 'top-14 before:-top-1'
                        } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/export:scale-100 group-hover/export:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
                      >
                        Export modified file
                      </div>
                    </div>
                  )}
                  <div className="relative hidden group/download group-hover/ellipses:flex group-hover/ellipses:justify-center mr-2 hover:bg-gray-500 rounded">
                    <button
                      className="w-full h-full p-2.5 transition ease-in-out delay-100 hover:scale-110"
                      onClick={downloadFile(file)}
                    >
                      <FileUploadOutlinedIcon sx={{ fontSize: 20 }} />
                    </button>
                    <div
                      className={`absolute ${
                        i === files.length - 1
                          ? files.length === 1
                            ? 'bottom-8 before:-bottom-1'
                            : 'bottom-14 before:-bottom-1'
                          : 'top-14 before:-top-1'
                      } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/download:scale-100 group-hover/download:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:left-0  before:right-0 before:mx-auto`}
                    >
                      Export original file
                    </div>
                  </div>
                  <div className="relative hidden group/deletefile group-hover/ellipses:flex group-hover/ellipses:justify-center hover:bg-gray-500 rounded">
                    <button
                      className="w-full h-full p-2.5 transition ease-in-out delay-100 hover:scale-110"
                      onClick={() =>
                        showPopup({
                          ...DELETE_FILE,
                          onClick: () => deletePDF(file.fileId),
                        })
                      }
                    >
                      <DeleteOutlineOutlinedIcon sx={{ fontSize: 20 }} />
                    </button>
                    <div
                      className={`absolute  ${
                        i === files.length - 1
                          ? files.length === 1
                            ? 'bottom-8 before:-bottom-1'
                            : 'bottom-14 before:-bottom-1'
                          : 'top-14 before:-top-1'
                      } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/deletefile:scale-100 group-hover/deletefile:w-fit before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:left-0  before:right-0 before:mx-auto`}
                    >
                      Delete
                    </div>
                  </div>
                  <div className="flex p-2.5   justify-center h-full w-full group-hover/ellipses:hidden">
                    <button className="w-full h-full">
                      <MoreHorizOutlinedIcon sx={{ fontSize: 20 }} />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default FileUnorderedList
