import { Dispatch, useCallback, useEffect, useRef, useState } from 'react'
import useClickOutside from '../../hooks/useClickOutside'
import {
  DynamoFile,
  FileStatus,
  RotationType,
  SearchAllType,
  TestDataOcr,
} from '../../types'
import { getFileV2 } from '../../api'
import { ClockLoader } from 'react-spinners'
import { NavigateFunction } from 'react-router-dom'
import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { ReaderAPI } from '../PdfViewer/types'

type Props = {
  files: DynamoFile[]
  degree: RotationType
  fileId: string
  filePage: number
  navigate: NavigateFunction
  setCurrentPage: Dispatch<React.SetStateAction<number>>
  setSearchAllItem: Dispatch<React.SetStateAction<string[]>>
  setFileId: Dispatch<React.SetStateAction<string>>
  setSearchAllInput: () => void
  readerAPI: ReaderAPI | null
}

const getFilesOCR = async (files: DynamoFile[]) => {
  const hashMap = new Map<[string, string], TestDataOcr>()

  const promises = files.map((file) => getFileV2(file.fileId))

  const result = await Promise.all(promises)

  for (const file of result) {
    hashMap.set([file.id, file.fileName], file.ocr)
  }

  return hashMap
}

// const worker = new Worker(
//   new URL('../../workers/search_worker.ts', import.meta.url)
// )

const SearchAllComboBox = ({
  files,
  degree,
  navigate,
  filePage,
  fileId,
  setCurrentPage,
  setSearchAllItem,
  setSearchAllInput,
  readerAPI,
}: Props) => {
  const [query, setQuery] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [possibleResults, setPossibleResults] = useState<SearchAllType[]>([])
  const [loading, setLoading] = useState(false)
  const workerRef = useRef<Worker>()
  // const [, setIsError] = useState(false)

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('../../workers/search_worker.ts', import.meta.url)
    )

    return () => workerRef.current?.terminate()
  }, [])

  useEffect(() => {
    const ids = possibleResults
      .filter((re) => re.fileId === fileId && re.filePage === filePage)
      .map((re) => re.blockId)
    setSearchAllItem(ids)
  }, [fileId, filePage, setSearchAllItem, possibleResults])

  const reset = () => {
    setPossibleResults([])
    setShowOptions(false)
    setSearchAllItem([])
    // enableRibbonAfterSearchAll()
    setSearchAllInput()
  }

  useClickOutside<HTMLDivElement>({
    ref: containerRef,
    action: () => {
      if (!query || !query.trim()) {
        reset()
      }
    },
  })

  const setSearchAllItemHelper = useCallback(
    (fileId: string, filePage: number, results: SearchAllType[]) => {
      const ids = results
        .filter((re) => re.fileId === fileId && re.filePage === filePage)
        .map((re) => re.blockId)
      setSearchAllItem(ids)
    },
    [setSearchAllItem]
  )

  useEffect(() => {
    const handlder = (message: any) => {
      const { results, error } = message.data
      if (error) {
        console.error(error)
        return
      }
      if (results && results.length > 0) {
        setPossibleResults(results)
        setSearchAllItemHelper(fileId, filePage, results)
      } else {
        setPossibleResults([])
        setSearchAllItem([])
      }
      setLoading(false)
    }
    workerRef.current?.addEventListener('message', handlder)

    return () => workerRef.current?.removeEventListener('message', handlder)
  }, [fileId, filePage, setSearchAllItem, setSearchAllItemHelper])

  const onSearchButtonClick = async () => {
    if (loading) return
    if (query.trim()) {
      setLoading(true)
      const filteredFiles = files.filter(
        (file) => file.type === 'FILE' && file.status === FileStatus.SUCCEEDED
      )
      if (!workerRef.current) {
        setLoading(false)
        return
      }
      getFilesOCR(filteredFiles).then((map) => {
        workerRef.current?.postMessage({
          map,
          query: query.toLocaleLowerCase(),
          degree: degree.curr,
        })
      })
    }
  }

  const onItemClick = (item: [string, string, number]) => () => {
    const [itemFileId, , itemFilePage] = item
    if (itemFileId === fileId && itemFilePage === filePage) return
    if (itemFileId !== fileId) {
      setSearchAllItemHelper(itemFileId, itemFilePage, possibleResults)
      navigate(`/file/${itemFileId}`, {
        replace: true,
        state: { page: itemFilePage },
      })
      return
    }
    if (filePage !== itemFilePage) {
      setSearchAllItemHelper(fileId, itemFilePage, possibleResults)
      setCurrentPage(itemFilePage)
      readerAPI?.jumpToPage(itemFilePage - 1)
      return
    }
  }
  const arr: [string, [string, string, number]][] = possibleResults.map(
    (result) => {
      const tuple: [string, string, number] = [
        result.fileId,
        result.fileName,
        result.filePage,
      ]
      return [tuple.join('-'), tuple]
    }
  )
  const possibleResultMap = new Map(arr)

  const onSearchInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter' && !loading) onSearchButtonClick()
  }

  const clearSearchField = () => {
    setQuery('')
    setPossibleResults([])
    setSearchAllItem([])
  }

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
    >
      <div className="relative">
        <div className="relative w-full cursor-default overflow-hidden rounded-sm text-left shadow-md  focus:ring-2 focus:ring-teal-300/75 focus:ring-offset-2 focus:ring-offset-teal-300 sm:text-sm">
          <input
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 animate-pulse focus:animate-none"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Search files"
            onKeyUp={onSearchInputKeyUp}
            autoComplete="off"
            autoCorrect="off"
          />

          {!loading && (
            <div className="absolute flex inset-y-0 right-0 items-center pr-2">
              <button className="mr-1" onClick={clearSearchField}>
                <CloseOutlinedIcon sx={{ fontSize: 16 }} />
              </button>
              <button onClick={onSearchButtonClick}>
                <SearchOutlinedIcon sx={{ fontSize: 16 }} />
              </button>
            </div>
            // <button
            //   className="absolute inset-y-0 right-0 items-center pr-2"
            //   onClick={onSearchButtonClick}
            // >
            //   <MdOutlineSearch />
            // </button>
          )}

          {loading && (
            <button
              className="absolute inset-y-0 right-0 items-center pr-2"
              onClick={reset}
            >
              <ClockLoader color="#36d7b7" size={15} />
            </button>
          )}
        </div>

        {showOptions && (
          <div className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {possibleResultMap.size === 0 && (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700 ">
                Nothing found.
              </div>
            )}
            {possibleResultMap.size > 0 &&
              Array.from(possibleResultMap.values()).map((item) => (
                <div
                  onClick={onItemClick(item)}
                  key={item.join('-')}
                  className={`relative cursor-default select-none py-2 pl-2 pr-4 
                                      hover:bg-teal-600 hover:text-white text-gray-900
                                    `}
                >
                  {`${item[1]}, page: ${item[2]}`}
                  {/* <div className="absolute flex justify-center inset-y-0 right-0 items-center pr-2">
                    <button
                      className="text-xs  hover:bg-teal-500 text-gray-800 py-2 px-2"
                      onClick={onItemClick(item)}
                    >
                      View
                    </button>
                    <button className="text-xs  hover:bg-teal-500 text-gray-800 py-2 px-2 ">
                      Snip
                    </button>
                  </div> */}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchAllComboBox
