import axios from 'axios'
import {
  CreateRecordBatchRequest,
  CreateRecordBatchResponse,
  DynamoFile,
  ExtractlyPlusFile,
  FileRecord,
  FileStatus,
  GetFileType,
  OCRData,
  ParseTableResult,
  RatioPageArea,
  TestDataOcr,
  TextcutRectangle,
} from '../types'
import {
  fetchPDFFromLocal,
  findLocalFileRecordWithFileId,
  findLocalFileRecordWithFileIds,
  getLocalFileRecords,
  updateLocalFileRecords,
} from '../workbook'
import {
  addFileToDB,
  getFileFromDB,
  getFilesFromDB,
  removeFileFromDB,
} from '../utils/db'
import { breakDownOcr } from '../utils/ocr'
import { getDateBlocks } from '../utils/fuzzy'
import dayjs from 'dayjs'
import { createWorkerFactory } from '@shopify/web-worker'

axios.defaults.baseURL = `${process.env.REACT_APP_URL}`

const createWorker = createWorkerFactory(
  () => import('../workers/listFile_worker')
)

export const putFileToSignedURL = (url: string, file: File) =>
  axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
    transformRequest: (data, headers) => {
      delete headers['Authorization']
      return data
    },
  })

export const checkSubscriptionStatus = async () => {
  const data = await axios.get<{
    data: {
      isSubscribed: boolean
      firmId: string
    }
  }>('/check-subscription')
  return data.data.data
}

export const startProcessingDoc = async (
  fileId: string,
  contentType: string
) => {
  const body = { fileId, contentType }
  const data = await axios.post<{
    message: string
  }>('/file/process-doc', body)
  // console.log(data)
  return data.data.message
}

export const startProcessingRedactedDoc = async (
  fileId: string,
  contentType: string
) => {
  const body = { fileId, contentType }
  const data = await axios.post<{
    message: string
  }>('/file/process-redacted-doc', body)
  console.log(data)
  return data.data.message
}

axios.interceptors.request.use(async (config) => {
  try {
    const token = await Office.auth.getAccessToken()
    config.headers.Authorization = `Bearer ${token}`
  } catch (err) {
    console.error('Failed to acquire token silently', err)
  }
  return config
})

// export const listAllFiles = async () => {
//   const data = await axios.get<{ data: DynamoFile[] }>('/file/all')
//   const files = data.data.data
//   const records: FileRecord[] = files.map((file) => ({
//     fileId: file.fileId,
//     status: file.status,
//     userId: file.userId,
//   }))
//   await updateLocalFileRecords(records)
//   return files
// }

export const listAllFilesV2 = async () => {
  const start = dayjs()
  const [remoteFileRecords, localFileRecords] = await getLocalFileRecords()
  if (!remoteFileRecords.length && !localFileRecords.length) return []
  const files = await fetchRemoteFiles(remoteFileRecords)
  const succeededFiles = files.filter(
    (file) => file.status === FileStatus.SUCCEEDED
  )
  const worker = createWorker()
  const promises = succeededFiles.map((file) => worker.getAndCacheFile(file))
  await Promise.all(promises)

  const localFiles = await worker.fetchLocalFiles(localFileRecords)

  const records: FileRecord[] = files.map((file) => ({
    fileId: file.fileId,
    status: file.status,
    userId: file.userId,
  }))
  await updateLocalFileRecords(records)
  const end = dayjs()
  console.log(`List all files took: ${end.diff(start, 'ms')}`)
  // console.log(files)
  const cloudFiles: DynamoFile[] = files.map((file) => ({
    ...file,
    isLocal: false,
  }))
  const localFs: DynamoFile[] = localFiles.map((file) => ({
    ...file,
    isLocal: true,
  }))
  return [...cloudFiles, ...localFs]
}

const fetchRemoteFiles = async (remotesFileRecords: FileRecord[]) => {
  if (!remotesFileRecords.length) return []
  const body = remotesFileRecords.map((record) => ({
    fileId: record.fileId,
    userId: record.userId,
  }))

  const data = await axios.post<{ data: DynamoFile[] }>('/file/batch-get', body)
  return data.data.data
}

export const createRecord = (
  fileName: string,
  /** @deprecated in favor of `parentFolderId` */
  relativePath: string,
  contentType: string
) =>
  axios.post<{ data: { fileId: string } }>('/file', {
    fileName,
    /** @deprecated in favor of `parentFolderId` */
    dir: relativePath,
    contentType,
  })

export const createRecordBatch = (
  files: CreateRecordBatchRequest['files']
): Promise<CreateRecordBatchResponse> =>
  axios.post('/file/batch', { files }).then((res) => res.data)

export const getSignedUrl = (fileId: string, contentType: string) =>
  axios.post<{ uploadURL: string }>('/get-signed-url', { fileId, contentType })

export const getFile = async (id: string): Promise<[Blob, TestDataOcr]> => {
  const file = await getFileFromDB(id)
  if (file) {
    return [file.pdf, file.ocr]
  }
  const res = await axios.get<{ data: GetFileType }>(`/file/${id}`)
  const { pdfUrl, ocrUrl } = res.data.data
  const pdf = await fetch(pdfUrl)
  const pdfBlob = await pdf.blob()
  const ocr = await fetch(ocrUrl)
  const ocrJson = await ocr.json()
  return [pdfBlob, ocrJson]
}

export const getFileV2 = async (id: string): Promise<ExtractlyPlusFile> => {
  const record = await findLocalFileRecordWithFileId(id)
  if (!record) throw new Error('Internal Error')
  const file = await getFileFromDB(id)
  if (file)
    return {
      ...file,
      isLocal: record.isLocal,
    }

  if (record.isLocal) {
    const localFile = await fetchPDFFromLocal(record.fileId)
    if (!localFile)
      throw new Error(`Cannot fetch file from local, where fileId=${id}`)
    const [, , , lines, words] = breakDownOcr(localFile.ocr, 0)
    const blocks = [...lines.values(), ...words.values()]
    const dateBlocks = getDateBlocks(blocks)
    await addFileToDB({
      id: localFile.fileId,
      type: localFile.type,
      folderName: localFile.folderName,
      parentFolderId: localFile.parentFolderId,
      createdAt: new Date(),
      extractionMethod: localFile.extractionMethod,
      fileName: localFile.fileName,
      ocr: localFile.ocr,
      ocrDateCache: dateBlocks,
      pdf: localFile.pdfBlob,
      extractionEngine: localFile.extractionEngine,
    })
    return {
      id: localFile.fileId,
      type: localFile.type,
      folderName: localFile.folderName,
      parentFolderId: localFile.parentFolderId,
      createdAt: new Date(),
      fileName: localFile.fileName,
      ocr: localFile.ocr,
      pdf: localFile.pdfBlob,
      ocrDateCache: dateBlocks,
      extractionMethod: localFile.extractionMethod,
      isLocal: true,
    }
  }

  const res = await axios.get<{ data: GetFileType }>(
    `/file/${id}/${record.userId}`
  )
  const data = res.data.data
  const pdf = await (await fetch(data.pdfUrl)).blob()
  const ocr: TestDataOcr = await (await fetch(data.ocrUrl)).json()
  const [, , , lines, words] = breakDownOcr(ocr, 0)
  const blocks = [...lines.values(), ...words.values()]
  const dateBlocks = getDateBlocks(blocks)
  const extractlyPlusFile: ExtractlyPlusFile = {
    id: data.fileId,
    createdAt: new Date(),
    fileName: data.fileName,
    type: data.type,
    folderName: data.folderName,
    parentFolderId: data.parentFolderId,
    dir: data.dir,
    ocr,
    pdf,
    ocrDateCache: dateBlocks,
    extractionMethod: data.extractionMethod,
    isLocal: false,
  }
  await addFileToDB(extractlyPlusFile)
  return extractlyPlusFile
}

export const getFileBatchV2 = async (
  fileIds: string[]
): Promise<ExtractlyPlusFile[]> => {
  const records = await findLocalFileRecordWithFileIds(fileIds)

  if (records.some((r) => !r)) throw new Error('Internal Error')
  const files = await getFilesFromDB(fileIds)
  if (files.length === fileIds.length) return files

  const results: ExtractlyPlusFile[] = []
  for (const record of records) {
    const res = await axios.get<{ data: GetFileType }>(
      `/file/${record.fileId}/${record.userId}`
    )
    const data = res.data.data
    const pdf = await (await fetch(data.pdfUrl)).blob()
    const ocr: TestDataOcr = await (await fetch(data.ocrUrl)).json()
    const [, , , lines, words] = breakDownOcr(ocr, 0)
    const blocks = [...lines.values(), ...words.values()]
    const dateBlocks = getDateBlocks(blocks)
    const extractlyPlusFile: ExtractlyPlusFile = {
      id: data.fileId,
      createdAt: new Date(),
      fileName: data.fileName,
      type: data.type,
      folderName: data.folderName,
      parentFolderId: data.parentFolderId,
      dir: data.dir,
      ocr,
      pdf,
      ocrDateCache: dateBlocks,
      extractionMethod: data.extractionMethod,
    }
    await addFileToDB(extractlyPlusFile)
    results.push(extractlyPlusFile)
  }
  return results
}

export const getS3FileInfo = async (id: string) => {
  const record = await findLocalFileRecordWithFileId(id)
  if (!record) throw new Error('Internal Error')
  const res = await axios.get<{ data: GetFileType }>(
    `/file/${id}/${record.userId}`
  )
  return res.data.data
}

// export const getFilesWithFileIds = async (fileIds: string[]) => {
//   const promises = fileIds.map((id) => getFile(id))
//   const res = await Promise.all(promises)
//   const arr: FileRecord[] = res.map((r) => ({
//     fileId: r.fileId,
//     status: r.status,
//     userId: r.userId,
//   }))
//   await updateLocalFileRecords(arr)
//   return res
// }

export const getOcr = async (ocrUrl: string) => {
  const res = await axios.get<OCRData>(ocrUrl, {
    transformRequest: (data, headers) => {
      delete headers['Authorization']
      return data
    },
  })
  return res.data
}

export const deleteFile = async (fileId: string) => {
  await removeFileFromDB(fileId)
  return axios.delete<{ message: string }>(`/file/${fileId}`)
}

// export const replacePdfAndOcr = async (fileId: string) => {
//   const res = await axios.put<{
//     data: { uploadUrlPdf: string; uploadUrlOcr: string }
//   }>(`/file/update-attachments/${fileId}`)
//   return [res.data.data.uploadUrlPdf, res.data.data.uploadUrlOcr]
// }

export const redactFile = async (
  redacts: TextcutRectangle[],
  fileId: string
) => {
  await removeFileFromDB(fileId)
  const file = await getS3FileInfo(fileId)
  const redactions = redacts.map((redact) => ({
    canvas_height: redact.ocrH,
    canvas_width: redact.ocrW,
    rect_height: redact.h,
    rect_width: redact.w,
    rect_in_x0: redact.x,
    rect_in_y0: redact.y,
    page_no: redact.filePage - 1,
  }))
  return axios.post(`${process.env.REACT_APP_REDACT_URL}`, {
    file_id: file.fileId,
    user_id: file.userId,
    input_pdf_s3_key: file.originalFileKey,
    redactions,
  })
}

export const trimPages = async (fileId: string, pagesToKeep: Array<number>) => {
  await removeFileFromDB(fileId)
  const body = { fileId, pagesToKeep }
  const data = await axios.post<{
    message: string
  }>('/file/trim-pages', body)
  console.log(data)
  return data.data.message
}

// export const estimateProcessingTime = async (
//   numOfDocs: number,
//   totalPages: number
// ) => {
//   const body = { numOfDocs, totalPages }
//   const res = await axios.post<{
//     data: { estimatedTime: number }
//   }>('/file/estimate-process-time', body)
//   return res.data.data.estimatedTime
// }

export const parseTable = async (body: {
  fileId: string
  ratioPageArea?: RatioPageArea
}) => {
  const res = await axios.post<{ success: boolean; data: ParseTableResult[] }>(
    `${process.env.REACT_APP_URL}/table/parse`,
    body
  )
  return res.data
}

export const listSuccessFiles = async () => {
  const start = dayjs()
  const [remoteFileRecords, localFileRecords] = await getLocalFileRecords()
  if (!remoteFileRecords.length && !localFileRecords.length) return []
  const files = await fetchRemoteFiles(remoteFileRecords)
  const succeededFiles = files.filter(
    (file) => file.status === FileStatus.SUCCEEDED
  )
  const worker = createWorker()
  const promises = succeededFiles.map((file) => worker.getAndCacheFile(file))
  await Promise.all(promises)

  const localFiles = await worker.fetchLocalFiles(localFileRecords)

  const records: FileRecord[] = files.map((file) => ({
    fileId: file.fileId,
    status: file.status,
    userId: file.userId,
  }))
  await updateLocalFileRecords(records)
  const end = dayjs()
  console.log(`List all files took: ${end.diff(start, 'ms')}`)
  // console.log(files)
  const cloudFiles: DynamoFile[] = succeededFiles.map((file) => ({
    ...file,
    isLocal: false,
  }))
  const localFs: DynamoFile[] = localFiles.map((file) => ({
    ...file,
    isLocal: true,
  }))
  return [...cloudFiles, ...localFs]
}

export const updateFilDir = ({
  fileId,
  dir,
}: {
  fileId: string
  dir: string
}) =>
  axios.post(`${process.env.REACT_APP_URL}/file/update-dir`, {
    fileId,
    dir,
  })

export const updateParentFolder = async (
  fileId: string,
  parentFolderId: string
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_URL}/file/update-parent`,
    {
      fileId,
      parentFolderId,
    }
  )
  return res.data
}

export const createFolder = async (
  parentFolderId: string | undefined,
  folderName: string
) => {
  const res = await axios.post<{
    data: { folderId: string }
  }>(`${process.env.REACT_APP_URL}/file/folder`, { parentFolderId, folderName })
  return res.data.data.folderId
}
