import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { Cut, OfficeRuntimeAccessToken, OfficeSimpleEvent } from './types'
import { Provider } from 'react-redux'
import { store } from './store'
import {
  getOfficeRuntimeAccessToken,
  publishBindingOnDataChangeEvent,
  publishOnReferenceSelectedEvent,
} from './utils/common'
import { removeInvalidBindings } from './workbook'
import { QueryClient, QueryClientProvider } from 'react-query'
import FormModalProvider from './components/FormModal/FormModalProvider'
import ErrorMsg from './components/ErrorMsg'
import {
  INITIALIZE_OFFICE_SETTINGS_ERROR,
  INITIALIZE_OFFICE_SETTINGS_ERROR_CONTENT,
} from './constant'
import { BeatLoader } from 'react-spinners'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient()

/**
 * Publish textcut event
 * @param args
 */
const publishCutEvent =
  (cutType: Cut) => (args: Office.AddinCommands.Event) => {
    try {
      const textcutEvent = new CustomEvent('CutType', {
        detail: { type: cutType },
      })
      window.dispatchEvent(textcutEvent)
    } catch (err) {
      console.error(err)
    } finally {
      // indicate office that the action has completed
      args.completed()
    }
  }

// const executeDataMatch = () => (args: Office.AddinCommands.Event) => {
//   try {
//     // eslint-disable-next-line no-restricted-globals
//     location.href = 'https://localhost:3000/data-match'
//   } catch (err) {
//     console.error(err)
//   } finally {
//     // indicate office that the action has completed
//     args.completed()
//   }
// }

const publishNavigationEvent =
  (url: string) => (args: Office.AddinCommands.Event) => {
    try {
      const navigationEvent = new CustomEvent('Navigation', {
        detail: { url },
      })
      window.dispatchEvent(navigationEvent)
    } catch (err) {
      console.error(err)
    } finally {
      // indicate office that the action has completed
      args.completed()
    }
  }

// const publishTransposeEvent = (args: Office.AddinCommands.Event) => {
//   try {
//     const transposeEvent = new CustomEvent('TRANSPOSE', {
//       detail: null,
//     })
//     window.dispatchEvent(transposeEvent)
//   } catch (err) {
//     console.error(err)
//   } finally {
//     args.completed()
//   }
// }

const publishRefDeletionEvent = async (args: Office.AddinCommands.Event) => {
  try {
    const [sheetId, rangeAddress] = await Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getActiveWorksheet()
      const range = ctx.workbook.getSelectedRange()
      sheet.load('id')
      range.load('address')
      await ctx.sync()
      const [, addr] = range.address.split('!')
      return [sheet.id, addr]
    })

    const refDeletionEvent = new CustomEvent('ReferenceDeletion', {
      detail: {
        sheetId,
        rangeAddress,
      },
    })

    dispatchEvent(refDeletionEvent)
  } catch (err) {
    console.error(err)
  } finally {
    args.completed()
  }
}

// const publishLogoutEvent = (args: Office.AddinCommands.Event) => {
//   try {
//     const event = new CustomEvent('LOGOUT', {})
//     window.dispatchEvent(event)
//   } catch (err) {
//     console.error(err)
//   } finally {
//     args.completed()
//   }
// }

const publishOpenFeedbackEvent = (args: Office.AddinCommands.Event) => {
  try {
    window.open('https://extractly.atlassian.net/servicedesk/customer/portal/1')
  } catch (err) {
    console.error(err)
  } finally {
    args.completed()
  }

  // try {
  //   const event = new CustomEvent('OPEN_FEEDBACK', {})
  //   window.dispatchEvent(event)
  // } catch (err) {
  //   console.error(err)
  // } finally {
  //   args.completed()
  // }
}

const publishSimpleEvent =
  (eventType: string) => (args: Office.AddinCommands.Event) => {
    try {
      const event = new CustomEvent(eventType, {})
      window.dispatchEvent(event)
    } catch (err) {
      console.error(err)
    } finally {
      args.completed()
    }
  }

const openUserGuide = (args: Office.AddinCommands.Event) => {
  try {
    window.open(
      'https://extractly.ai/wp-content/uploads/2024/05/Extractly-Guide-v2.pdf'
    )
  } catch (err) {
    console.error(err)
  } finally {
    args.completed()
  }
}

const AppWrapper = () => {
  const [appLoading, setAppLoading] = useState(true)
  const [appError, setAppError] = useState(false)
  const [userInfo, setUserInfo] = useState<OfficeRuntimeAccessToken | null>(
    null
  )
  const navigate = useNavigate()

  useEffect(() => {
    const func = async () => {
      const token = await getOfficeRuntimeAccessToken()
      Office.actions.associate('extractText', publishCutEvent(Cut.TEXTCUT))
      Office.actions.associate('extractTable', publishCutEvent(Cut.TABLECUT))
      // Office.actions.associate('xRayWord', publishCutEvent(Cut.WORD))
      // Office.actions.associate('xRayLine', publishCutEvent(Cut.LINE))
      Office.actions.associate('sum', publishCutEvent(Cut.SUM))
      Office.actions.associate('Redact', publishCutEvent(Cut.REDACT))
      // Office.actions.associate('extractTables', publishCutEvent(Cut.TABLES))
      Office.actions.associate(
        'dataMatching',
        publishNavigationEvent('/data-match')
      )
      // Office.actions.associate(
      //   'UploadFilePage',
      //   publishNavigationEvent('/upload')
      // )
      Office.actions.associate('FilesPage', publishNavigationEvent('/files'))
      // Office.actions.associate('transpose', publishTransposeEvent)
      Office.actions.associate('deleteReference', publishRefDeletionEvent)
      // Office.actions.associate('logout', publishLogoutEvent)
      Office.actions.associate('openFeedback', publishOpenFeedbackEvent)
      Office.actions.associate(
        'deleteAllReferences',
        publishSimpleEvent(OfficeSimpleEvent.DELETE_ALL_REFERENCES)
      )
      Office.actions.associate(
        'Undo',
        publishSimpleEvent(OfficeSimpleEvent.UNDO)
      )
      Office.actions.associate(
        'Redo',
        publishSimpleEvent(OfficeSimpleEvent.REDO)
      )
      Office.actions.associate('userGuide', openUserGuide)
      // Office.actions.associate(
      //   'extractAllTables',
      //   publishSimpleEvent(OfficeSimpleEvent.ALL_TABLES)
      // )

      await removeInvalidBindings()

      await Excel.run(async (ctx) => {
        const bindings = ctx.workbook.bindings
        bindings.load('items')
        await ctx.sync()
        let items = bindings.items
        for (const item of items) item.load('id')
        await ctx.sync()
        for (const item of items) {
          item.onSelectionChanged.add(async (item) => {
            publishOnReferenceSelectedEvent(item.binding.id)
          })
          item.onDataChanged.add(async (item) => {
            publishBindingOnDataChangeEvent(item.binding.id)
          })
        }
      })

      // await $.ajax({
      //   url: 'https://auxilis-au.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=c1ae5d29',
      //   type: 'get',
      //   cache: true,
      //   dataType: 'script',
      //   success: function () {
      //     console.log('success')

      //     window.ATL_JQ_PAGE_PROPS = $.extend(window.ATL_JQ_PAGE_PROPS, {
      //       fieldValues: {
      //         email: token.preferred_username,
      //         fullname: token.name,
      //       },
      //     })
      //   },
      // })
      return token
    }
    setAppLoading(true)
    setAppError(false)
    setUserInfo(null)
    func()
      .then((token) =>
        setUserInfo({
          name: token.name,
          oid: token.oid,
          preferred_username: token.preferred_username,
        })
      )
      .catch(() => setAppError(true))
      .finally(() => {
        setAppLoading(false)
      })
  }, [])

  if (appLoading)
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <div className="flex flex-col justify-center items-center animate-bounce">
          <div>
            <BeatLoader size={20} color="#36d7b7" />
          </div>
          <div className="font-semibold">Initializing Office settings...</div>
        </div>
      </div>
    )

  if (appError || !userInfo)
    return (
      <div className="w-full h-screen p-4 flex justify-center">
        <div>
          <ErrorMsg
            title={INITIALIZE_OFFICE_SETTINGS_ERROR}
            content={INITIALIZE_OFFICE_SETTINGS_ERROR_CONTENT}
            navigate={navigate}
          />
        </div>
      </div>
    )

  return (
    <App
      userId={userInfo.oid}
      userEmail={userInfo.preferred_username}
      username={userInfo.name}
    />
  )
}

// render react compoent when office is ready
Office.onReady(async () => {
  try {
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <FormModalProvider>
                <AppWrapper />
              </FormModalProvider>
            </QueryClientProvider>
          </Provider>
        </BrowserRouter>
      </React.StrictMode>
    )
  } catch (err) {
    console.error(err)
  }
})
